<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductStockSuppliesCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                    @keyup.enter="getData"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="productStockSupplies"
                item-key="id"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.created_by_name }}</td>
                    <td>{{ row.item.supplies_name }}</td>
                    <td>{{ row.item.receiver_name }}</td>
                    <td>
                      Rp.
                      {{ formatPrice(row.item.total_price, row.item.price) }}
                    </td>
                    <td>{{ row.item.status_name }}</td>
                    <td>{{ row.item.created_at }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductStockSuppliesShow',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-primary mr-1"
                      >
                        Show
                      </router-link>
                    </td>
                    <the-modal
                      :modal-id="row.item.id"
                      :modal-title="row.item.created_by_name"
                      :modalSupplier="row.item.supplies_name"
                      :modalReceiver="row.item.receiver_name"
                    ></the-modal>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="lastPage"
                    :total-visible="itemsPerPage"
                    @input="getData"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import TheModal from './TheModal.vue';
export default {
  name: 'ProductStockSupplies',
  components: { TheModal },
  data() {
    return {
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      name: 'Product Stock Supplies',
      productStockSupplies: [],
      loading: false,
      sortBy: 'created_at',
      sortDesc: true,
      total: 0,
      lastPage: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'stock_supplies', {
          params: {
            page: this.page,
            search: this.search,
          },
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.loading = false;
          this.productStockSupplies = result.data.data.data;
          this.total = result.data.data.total;
          this.itemsPerPage = result.data.data.per_page;
          this.lastPage = result.data.data.last_page;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatPrice(a, b) {
      let value;
      if (a > b) value = a;
      else value = b;

      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Request By',
          value: 'created_by_name',
        },
        {
          text: 'Supplier',
          value: 'supplies_name',
        },
        {
          text: 'Receiver',
          value: 'receciver_name',
        },
        {
          text: 'Total Biaya',
          value: 'amount',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Created At',
          value: 'created_at',
        },
        { text: 'Action' },
      ];
    },
  },
};
</script>
